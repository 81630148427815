import React from "react";
import Form from "./Form";
import ContactInfo from "./ContactInfo";

const ContactPage = () => {
  const address = {
    line1: "Q2 2nd Floor, CyberTowers, HITEC City, Hyderabad, Telangana 500081",
    phone: "+91 6309128874",
    email: "info@foremedlifesciences.com",
  };

  return (
    <div className={"contact-page-area-wrapper sp-y"}>
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-form-area contact-method">
                <h3>Send us a Message</h3>
                <Form />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contact-information contact-method">
                <div className="contact-info-con">
                  <h3>Contact Info</h3>
                  <ContactInfo address={address} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
